html, body { height: 100%; }
body { background-color: #eee !important; margin: 0; }
.badge{
    padding: .5rem !important;
    color: #eee !important;
    letter-spacing: .1rem !important;
    background-color: rgba(3, 49, 3, 0.9) !important;  
}

.gap{
display: flex;
height: 1.875em;
}
.router{
  color: #1b5e20 !important;
  text-decoration: underline !important;
}
.jazz-brunch {
  font-weight: 800 !important;
  padding: 1em 1.4em !important;
  font-size: 16px !important;
  border-radius: 4em !important;
  background-color: #c8193d !important;
  color: #fff !important;
  text-decoration: none !important;
  }

  .earth-day-btn{
    font-weight: 800 !important;
    padding: 1em 1.4em !important;
    font-size: 16px !important;
    border-radius: 4em !important;
    background-color: #B2DEA4 !important;
    color: #101415 !important;
    text-decoration: none !important;
  }
  
  img{
    border-radius: 30px !important; 
  }

  .card,mat-card{
    border-radius: 30px !important;
    border: none !important;
    padding: 1.5rem !important;
    
}
  form{
    background-color: #fff;
    padding: 1.4rem;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}
  .spacer{
    flex: 1 1;
  }

  mat-icon{
    vertical-align: middle;
}

.container{
  min-height: 100vh;
}

.back{
    margin: 1rem;
}
*{
  font-family: 'Quicksand', sans-serif;
}
  app-root{
    display: block;
  }